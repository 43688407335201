import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

import Layout from "../components/layout"
import SEO from "../components/seo"
import FixedContainer from '../components/global/FixedContainer';
import SecondaryPageHeader from '../components/SecondaryPageHeader'
import CallToAction from '../components/global/CallToAction';

const Wrapper = styled.div`
  ${tw`
    bg-gray-100 py-12
  `}
`;

const StyledH2 = styled.h2`
  border-bottom: 6px solid;
  ${tw`
    mb-8 font-display text-center inline-block font-black text-4xl border-lightBlue relative`
  }
  &::after{
    position:absolute;
    content: '';
    width: 100%;
    height:3px;
    background-color: #1e1f57;
    bottom:0;
    left: 0;
  }
`;

const Redactor = styled.div`
  & p{
    ${tw` mb-4 `}
  }
  & img{
    max-width:100%;
    height: auto;
  }
`;

const AboutPage = () => {
  const data = useStaticQuery(graphql`
  query {
    heroImage: file(relativePath: {eq: "issues_bg.jpg" }) {
      childImageSharp{
        fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <Layout>
      <SEO title="Home" />
      <SecondaryPageHeader heading={"About"} image={data.heroImage.childImageSharp.fluid} />
      <Wrapper>
        <FixedContainer bg={true}>
          <StyledH2>Meet Joel O'Dorisio</StyledH2>
          <Redactor>
            <p>
            Joel grew up in Columbus, OH where he benefited from a strong public education. He continued his education at Ohio State University, where he majored in fine arts. Joel is an educator, entrepreneur, dedicated father, and union organizer.
            </p>
            <h2><br />Experience</h2>
            <p>
            First as a business owner and now as a BGSU professor, Joel has approached his various careers with passion and professionalism. When he first arrived at BGSU he saw the need for a united voice for educators and in 2010, Joel was instrumental in creating the BGSU chapter of the American Association of University Professors (AAUP). Since then, he has served on the executive committee and the negotiating team for every employment contract, advocating for higher wages and competitive benefits for the hardworking faculty at BGSU.
            </p>
            <figure>
              <img src="https://d2u86e95w5oqta.cloudfront.net/images/joel-odorisio.jpg" data-image="8egbyj868680" />
            </figure>
            <p>
              <br /><br />
              Whether it was through his work as a small business owner or through his work with his labor union, Joel has spent his entire life crafting a better future for his community. He believes that the government should advocate for the public good, not corporate special interests. Joel will advocate for investing in public schools to give our children better opportunities than he received growing up in Ohio. He will fight for a vibrant working class and the belief that there is dignity in work.
            </p>
          </Redactor>
        </FixedContainer>
      </Wrapper>
      <CallToAction />
    </Layout>
  )
}

export default AboutPage
